/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, {
	type CSSProperties,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { cssMap, jsx } from '@compiled/react';
import { bind } from 'bind-event-listener';

import {
	OpenLayerObserver,
	useOpenLayerObserver,
} from '@atlaskit/layering/experimental/open-layer-observer';
import { fg } from '@atlaskit/platform-feature-flags';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import { HomeActionsElement } from '../../../context/home-actions/home-actions-context';
import { useSkipLink } from '../../../context/skip-links/skip-links-context';
import {
	contentHeightWhenFixed,
	contentInsetBlockStart,
	localSlotLayers,
	sideNavVar,
	UNSAFE_sideNavLayoutVar,
} from '../constants';
import { DangerouslyHoistSlotSizes } from '../hoist-slot-sizes-context';
import { DangerouslyHoistCssVarToDocumentRoot } from '../hoist-utils';
import { PanelSplitterProvider } from '../panel-splitter/provider';
import type { ResizeBounds } from '../panel-splitter/types';
import { usePrefixedUID } from '../use-prefixed-id';

import { sideNavFlyoutCloseDelayMs } from './flyout-close-delay-ms';
import { SideNavToggleButtonElement } from './toggle-button-context';
import { useSideNavVisibility } from './use-side-nav-visibility';
import {
	useSideNavVisibilityCallbacks,
	type VisibilityCallback,
} from './use-side-nav-visibility-callbacks';
import { useToggleSideNav } from './use-toggle-side-nav';

const panelSplitterResizingVar = '--n_snvRsz';

const widthResizeBounds: ResizeBounds = { min: '240px', max: '50vw' };

type SideNavState =
	| 'visible-all'
	| 'hidden-mobile-only'
	| 'hidden-desktop-only'
	| 'hidden-mobile-and-desktop'
	| 'flyout';

type FlyoutState =
	| { type: 'open' }
	| { type: 'is-dragging-from-flyout' }
	| { type: 'waiting-for-close'; abort: () => void }
	| { type: 'ready-to-close' }
	| { type: 'not-active' };

/**
 * Returns the current state of the side nav based on the provided props.
 * It has been ordered to ensure that the flyout state is least prioritised over the other toggled visibility states
 */
const getSideNavState = ({
	visibleOnDesktop,
	visibleOnMobile,
	isFlyoutVisible,
}: {
	visibleOnDesktop: boolean;
	visibleOnMobile: boolean;
	isFlyoutVisible: boolean;
}): SideNavState => {
	if (visibleOnDesktop && visibleOnMobile) {
		return 'visible-all';
	}

	if (!visibleOnMobile && visibleOnDesktop) {
		return 'hidden-mobile-only';
	}

	if (!visibleOnDesktop && isFlyoutVisible) {
		return 'flyout';
	}

	if (visibleOnMobile && !visibleOnDesktop) {
		return 'hidden-desktop-only';
	}

	return 'hidden-mobile-and-desktop';
};

const styles = cssMap({
	root: {
		backgroundColor: token('elevation.surface.overlay'),
		boxShadow: token('elevation.shadow.overlay'),
		gridArea: 'main / aside / aside / aside',
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// Since the side nav is always rendered ontop of other grid items across all viewports height is
		// always set.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		position: 'sticky',
		// For mobile viewports, the side nav will take up 90% of the screen width, up to a maximum of 320px (the default SideNav width)
		width: 'min(90%, 320px)',
		// On small viewports the side nav is displayed above other slots so we create a stacking context.
		// We keep the side nav with a stacking context always so it is rendered above main content.
		// This comes with a caveat that main is rendered underneath the side nav content so for any
		// menu dialogs rendered with "shouldRenderToParent" they could be cut off unintentionally.
		// Unfortunately this is the best of bad solutions.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.sideNav,
		'@media (min-width: 48rem)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			width: `var(${panelSplitterResizingVar}, var(${sideNavVar}))`,
		},
		'@media (min-width: 64rem)': {
			backgroundColor: token('elevation.surface'),
			boxShadow: 'initial',
			gridArea: 'side-nav',
		},
	},
	flyout: {
		'@media (min-width: 64rem)': {
			backgroundColor: token('elevation.surface.overlay'),
			boxShadow: token('elevation.shadow.overlay'),
			gridArea: 'main',
		},
	},
	interactionSurface: {
		// Taking full space of container to capture hover interactions on the entire side nav
		width: '100%',
		height: '100%',
	},
	flexContainer: {
		// This element controls the flex layout to position the slot elements correctly.
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	hiddenMobileAndDesktop: {
		display: 'none',
	},
	hiddenMobileOnly: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	hiddenDesktopOnly: {
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
});

type SideNavProps = {
	children: React.ReactNode;
	/**
	 * Whether the side nav should be collapsed by default __on desktop screens__.
	 *
	 * It is always collapsed by default for mobile screens.
	 *
	 * __Note:__ If using this prop, ensure that it is also provided to the `SideNavToggleButton`.
	 * This is to ensure the state is in sync before post-SSR hydration.
	 */
	defaultCollapsed?: boolean;
	defaultWidth?: number;
	testId?: string;
	label?: string;
	/**
	 * Called when the side nav is expanded.
	 */
	onExpand?: VisibilityCallback;
	/**
	 * Called when the side nav is collapsed.
	 */
	onCollapse?: VisibilityCallback;
	id?: string;
};

/**
 * We need an additional component layer so we can wrap the side nav in a `OpenLayerObserver` and have access to the
 * context value.
 */
function SideNavInternal({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	onExpand,
	onCollapse,
	id,
}: SideNavProps) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);
	const { visibleOnDesktop, visibleOnMobile, setVisibleOnDesktop } = useSideNavVisibility({
		defaultCollapsed,
	});
	const [width, setWidth] = useState(defaultWidth);
	const clampedWidth = `clamp(${widthResizeBounds.min}, ${width}px, ${widthResizeBounds.max})`;
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const navRef = useRef<HTMLDivElement | null>(null);
	const toggleButtonElement = useContext(SideNavToggleButtonElement);
	const homeActionsElement = useContext(HomeActionsElement);
	const devTimeOnlyAttributes: Record<string, string | boolean> = {};
	const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
	const openLayerObserver = useOpenLayerObserver();
	const flyoutStateRef = useRef<FlyoutState>({ type: 'not-active' });

	const sideNavState = getSideNavState({
		visibleOnDesktop,
		visibleOnMobile,
		isFlyoutVisible,
	});

	const updateFlyoutState = useMemo(() => {
		function tryAbortPendingClose() {
			if (flyoutStateRef.current.type === 'waiting-for-close') {
				flyoutStateRef.current.abort();
			}
		}

		function open() {
			tryAbortPendingClose();
			flyoutStateRef.current = { type: 'open' };
			setIsFlyoutVisible(true);
		}

		function close() {
			tryAbortPendingClose();
			flyoutStateRef.current = { type: 'not-active' };
			setIsFlyoutVisible(false);
		}

		return function onAction(
			action:
				| 'open'
				| 'drag-from-flyout-started'
				| 'drag-from-flyout-finished'
				| 'waiting-for-close'
				| 'ready-to-close'
				| 'force-close',
		) {
			if (action === 'drag-from-flyout-started') {
				open();
				flyoutStateRef.current = { type: 'is-dragging-from-flyout' };
				return;
			}

			if (action === 'drag-from-flyout-finished') {
				open();
				return;
			}

			// ignoring all actions until the drag is finished
			if (flyoutStateRef.current.type === 'is-dragging-from-flyout') {
				return;
			}

			if (action === 'open') {
				open();
				return;
			}

			if (action === 'waiting-for-close') {
				if (flyoutStateRef.current.type === 'waiting-for-close') {
					return;
				}

				// A timeout is used to close the flyout after a delay when the user mouses out of the flyout area, and to allow
				// us to cancel the close if the user mouses back in.
				const timeout = setTimeout(() => {
					updateFlyoutState('ready-to-close');
				}, sideNavFlyoutCloseDelayMs);

				flyoutStateRef.current = {
					type: 'waiting-for-close',
					abort() {
						clearTimeout(timeout);
					},
				};

				return;
			}

			if (action === 'ready-to-close') {
				// If there are no open layers, we can close the flyout.
				if (openLayerObserver.getCount() === 0) {
					close();
					return;
				}

				flyoutStateRef.current = { type: 'ready-to-close' };
				return;
			}

			if (action === 'force-close') {
				close();
				return;
			}
		};
	}, [openLayerObserver]);

	const toggleVisibility = useToggleSideNav();

	// Sync the visibility in context with the local state value after SSR hydration, as it contains the default value from props
	useEffect(() => {
		setVisibleOnDesktop(visibleOnDesktop);
	}, [setVisibleOnDesktop, visibleOnDesktop]);

	const handleExpand = useCallback<VisibilityCallback>(
		({ screen }) => {
			onExpand?.({ screen });

			// When the side nav gets expanded, we close the flyout to reset it.
			// This prevents the flyout from staying open and ensures we are respecting the user's intent to expand.
			updateFlyoutState('force-close');
		},
		[onExpand, updateFlyoutState],
	);

	const handleCollapse = useCallback<VisibilityCallback>(
		({ screen }) => {
			onCollapse?.({ screen });

			// When the side nav gets collapsed, we close the flyout to reset it.
			// This prevents the flyout from staying open and ensures we are respecting the user's intent to collapse.
			updateFlyoutState('force-close');
		},
		[onCollapse, updateFlyoutState],
	);

	useSideNavVisibilityCallbacks({
		onExpand: handleExpand,
		onCollapse: handleCollapse,
		visibleOnDesktop,
		visibleOnMobile,
	});

	useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				if (mediaQueryList.matches) {
					// We're transitioning from tablet to desktop viewport size.
					// We forcibly show the side nav if it was shown on mobile.
					if (visibleOnMobile && !visibleOnDesktop) {
						toggleVisibility();
					}
				}
			},
		});
	}, [toggleVisibility, visibleOnDesktop, visibleOnMobile]);

	useEffect(() => {
		if (!toggleButtonElement) {
			return;
		}

		return bind(toggleButtonElement, {
			type: 'mouseenter',
			listener() {
				// Only flyout the side nav if the user hovered while the side nav was collapsed
				if (visibleOnDesktop) {
					return;
				}

				// Only flyout the side nav on desktop viewports
				const { matches } = window.matchMedia('(min-width: 64rem)');
				if (matches) {
					updateFlyoutState('open');
				}
			},
		});
	}, [updateFlyoutState, toggleButtonElement, visibleOnDesktop]);

	useEffect(() => {
		if (!toggleButtonElement) {
			return;
		}

		return bind(toggleButtonElement, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				updateFlyoutState('waiting-for-close');
			},
		});
	}, [isFlyoutVisible, toggleButtonElement, updateFlyoutState]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		return bind(navRef.current, {
			type: 'mouseenter',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (visibleOnDesktop || !isFlyoutVisible) {
					return;
				}

				// The user mouses has moused back over the side nav
				updateFlyoutState('open');
			},
		});
	}, [isFlyoutVisible, updateFlyoutState, visibleOnDesktop]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		return bind(navRef.current, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				updateFlyoutState('waiting-for-close');
			},
		});
	}, [isFlyoutVisible, updateFlyoutState]);

	useEffect(() => {
		const nav = navRef.current;

		if (!nav) {
			return;
		}

		if (!isFlyoutVisible) {
			return;
		}

		if (!fg('platform_design_system_nav4_sidenav_flyout_dnd_fix')) {
			return;
		}

		return monitorForElements({
			canMonitor({ source }) {
				return nav.contains(source.element);
			},
			onGenerateDragPreview() {
				updateFlyoutState('drag-from-flyout-started');
			},
			onDrop({ location }) {
				// Always marking the drag and done
				updateFlyoutState('drag-from-flyout-finished');

				// If the user dropped outside of the flyout, we will close the flyout
				const underUsersPointer = document.elementFromPoint(
					location.current.input.clientX,
					location.current.input.clientY,
				);

				if (!nav.contains(underUsersPointer)) {
					updateFlyoutState('waiting-for-close');
				}
			},
		});
	}, [isFlyoutVisible, updateFlyoutState]);

	useEffect(() => {
		if (!homeActionsElement || !toggleButtonElement) {
			return;
		}

		return bind(homeActionsElement, {
			type: 'mouseover',
			listener(event) {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (visibleOnDesktop || !isFlyoutVisible) {
					return;
				}

				if (event.target === homeActionsElement) {
					// The mouse is directly over the home actions container, so cancel any pending flyout closes.
					updateFlyoutState('open');
					return;
				}

				if (event.target instanceof Element && toggleButtonElement.contains(event.target)) {
					// The mouse is over the toggle button or any of its children, so we don't want to close the flyout.
					// We also don't need to cancel any pending closes, as we have separate event listeners for the toggle button mouse events.
					return;
				}

				// The user has moused over a child element of the home actions container that isn't the toggle button, e.g. the app switcher or nav logo,
				// so we should close the flyout (with a delay).
				updateFlyoutState('waiting-for-close');
			},
		});
	}, [
		homeActionsElement,
		isFlyoutVisible,
		toggleButtonElement,
		visibleOnDesktop,
		updateFlyoutState,
	]);

	useEffect(() => {
		if (!homeActionsElement) {
			return;
		}

		return bind(homeActionsElement, {
			type: 'mouseleave',
			listener() {
				// If the side nav is not in flyout mode, we don't need to do anything
				if (!isFlyoutVisible) {
					return;
				}

				// The mouse has left the home actions container, so we should close the flyout with a delay.
				updateFlyoutState('waiting-for-close');
			},
		});
	}, [homeActionsElement, isFlyoutVisible, updateFlyoutState]);

	useEffect(() => {
		// Close the flyout if there are no more layers open and the user is not mousing over the
		// flyout areas (side nav, top bar home actions element)
		return openLayerObserver.onChange(function onChange({ count }) {
			if (flyoutStateRef.current.type === 'ready-to-close' && count === 0) {
				updateFlyoutState('force-close');
			}
		});
	}, [openLayerObserver, updateFlyoutState]);

	useEffect(() => {
		// Clear flyout close timer if being unmounted
		return function cleanupPendingFlyoutClose() {
			if (flyoutStateRef.current.type === 'waiting-for-close') {
				flyoutStateRef.current.abort();
			}
		};
	}, []);

	if (process.env.NODE_ENV !== 'production') {
		const visible: string[] = [];
		if (visibleOnMobile) {
			visible.push('small');
		}
		if (visibleOnDesktop) {
			visible.push('large');
		}
		if (isFlyoutVisible) {
			visible.push('flyout');
		}
		devTimeOnlyAttributes['data-visible'] = visible.length ? visible.join(',') : 'false';
	}

	return (
		<nav
			id={CID}
			{...devTimeOnlyAttributes}
			data-layout-slot
			data-private-request-curved-border-on-main={
				// Using `undefined` instead of `false` so the attribute is not included in the HTML output when false.
				sideNavState === 'visible-all' || sideNavState === 'hidden-mobile-only' ? true : undefined
			}
			data-private-show-inline-start-border-on-main={
				// Using `undefined` instead of `false` so the attribute is not included in the HTML output when false.
				sideNavState === 'visible-all' || sideNavState === 'hidden-mobile-only' ? true : undefined
			}
			aria-label={label}
			style={
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/enforce-style-prop
					[sideNavVar]: clampedWidth,
				} as CSSProperties
			}
			ref={navRef}
			css={[
				styles.root,
				sideNavState === 'hidden-mobile-only' && styles.hiddenMobileOnly,
				sideNavState === 'hidden-desktop-only' && styles.hiddenDesktopOnly,
				sideNavState === 'hidden-mobile-and-desktop' && styles.hiddenMobileAndDesktop,
				sideNavState === 'flyout' && styles.flyout,
			]}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_sideNavLayoutVar}
					value="0px"
					mediaQuery={media.above.md}
					responsiveValue={visibleOnDesktop ? clampedWidth : 0}
				/>
				// ------ END UNSAFE STYLES ------
			)}

			<PanelSplitterProvider
				panelRef={navRef}
				panelWidth={width}
				onCompleteResize={setWidth}
				resizeBounds={widthResizeBounds}
				resizingCssVar={panelSplitterResizingVar}
				isEnabled={sideNavState === 'visible-all' || sideNavState === 'hidden-mobile-only'}
			>
				<div css={styles.flexContainer}>{children}</div>
			</PanelSplitterProvider>
		</nav>
	);
}

export function SideNav({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	onExpand,
	onCollapse,
	id,
}: SideNavProps) {
	return (
		<OpenLayerObserver>
			<SideNavInternal
				defaultCollapsed={defaultCollapsed}
				defaultWidth={defaultWidth}
				testId={testId}
				label={label}
				onExpand={onExpand}
				onCollapse={onCollapse}
				id={id}
			>
				{children}
			</SideNavInternal>
		</OpenLayerObserver>
	);
}
